// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-knowledgeshare-blog-category-js": () => import("./../../../src/knowledgeshare/blog-category.js" /* webpackChunkName: "component---src-knowledgeshare-blog-category-js" */),
  "component---src-knowledgeshare-knowledgeshare-js": () => import("./../../../src/knowledgeshare/knowledgeshare.js" /* webpackChunkName: "component---src-knowledgeshare-knowledgeshare-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-blockchain-logistics-company-js": () => import("./../../../src/pages/blockchain-logistics-company.js" /* webpackChunkName: "component---src-pages-blockchain-logistics-company-js" */),
  "component---src-pages-businessadvisory-js": () => import("./../../../src/pages/businessadvisory.js" /* webpackChunkName: "component---src-pages-businessadvisory-js" */),
  "component---src-pages-casestudy-js": () => import("./../../../src/pages/casestudy.js" /* webpackChunkName: "component---src-pages-casestudy-js" */),
  "component---src-pages-clientsuccess-js": () => import("./../../../src/pages/clientsuccess.js" /* webpackChunkName: "component---src-pages-clientsuccess-js" */),
  "component---src-pages-cloudnative-js": () => import("./../../../src/pages/cloudnative.js" /* webpackChunkName: "component---src-pages-cloudnative-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-documentation-about-js": () => import("./../../../src/pages/documentation/about.js" /* webpackChunkName: "component---src-pages-documentation-about-js" */),
  "component---src-pages-documentation-api-generate-token-js": () => import("./../../../src/pages/documentation/API/generate-token.js" /* webpackChunkName: "component---src-pages-documentation-api-generate-token-js" */),
  "component---src-pages-documentation-api-update-execution-status-js": () => import("./../../../src/pages/documentation/API/update-execution-status.js" /* webpackChunkName: "component---src-pages-documentation-api-update-execution-status-js" */),
  "component---src-pages-documentation-automation-assign-job-js": () => import("./../../../src/pages/documentation/automation/assign-job.js" /* webpackChunkName: "component---src-pages-documentation-automation-assign-job-js" */),
  "component---src-pages-documentation-automation-running-execution-js": () => import("./../../../src/pages/documentation/automation/running-execution.js" /* webpackChunkName: "component---src-pages-documentation-automation-running-execution-js" */),
  "component---src-pages-documentation-automation-setting-up-the-environment-js": () => import("./../../../src/pages/documentation/automation/setting-up-the-environment.js" /* webpackChunkName: "component---src-pages-documentation-automation-setting-up-the-environment-js" */),
  "component---src-pages-documentation-configuration-configuration-settings-js": () => import("./../../../src/pages/documentation/configuration/configuration-settings.js" /* webpackChunkName: "component---src-pages-documentation-configuration-configuration-settings-js" */),
  "component---src-pages-documentation-configuration-customize-general-js": () => import("./../../../src/pages/documentation/configuration/customize-general.js" /* webpackChunkName: "component---src-pages-documentation-configuration-customize-general-js" */),
  "component---src-pages-documentation-configuration-customize-priorities-js": () => import("./../../../src/pages/documentation/configuration/customize-priorities.js" /* webpackChunkName: "component---src-pages-documentation-configuration-customize-priorities-js" */),
  "component---src-pages-documentation-configuration-customize-statuses-js": () => import("./../../../src/pages/documentation/configuration/customize-statuses.js" /* webpackChunkName: "component---src-pages-documentation-configuration-customize-statuses-js" */),
  "component---src-pages-documentation-dashboard-graph-info-js": () => import("./../../../src/pages/documentation/dashboard/graph-info.js" /* webpackChunkName: "component---src-pages-documentation-dashboard-graph-info-js" */),
  "component---src-pages-documentation-dashboard-introduction-js": () => import("./../../../src/pages/documentation/dashboard/introduction.js" /* webpackChunkName: "component---src-pages-documentation-dashboard-introduction-js" */),
  "component---src-pages-documentation-getting-started-adding-users-js": () => import("./../../../src/pages/documentation/getting-started/adding-users.js" /* webpackChunkName: "component---src-pages-documentation-getting-started-adding-users-js" */),
  "component---src-pages-documentation-getting-started-enableplugin-js": () => import("./../../../src/pages/documentation/getting-started/enableplugin.js" /* webpackChunkName: "component---src-pages-documentation-getting-started-enableplugin-js" */),
  "component---src-pages-documentation-getting-started-installation-js": () => import("./../../../src/pages/documentation/getting-started/installation.js" /* webpackChunkName: "component---src-pages-documentation-getting-started-installation-js" */),
  "component---src-pages-documentation-getting-started-registration-js": () => import("./../../../src/pages/documentation/getting-started/registration.js" /* webpackChunkName: "component---src-pages-documentation-getting-started-registration-js" */),
  "component---src-pages-documentation-project-team-add-role-js": () => import("./../../../src/pages/documentation/project-team/add-role.js" /* webpackChunkName: "component---src-pages-documentation-project-team-add-role-js" */),
  "component---src-pages-documentation-project-team-list-js": () => import("./../../../src/pages/documentation/project-team/list.js" /* webpackChunkName: "component---src-pages-documentation-project-team-list-js" */),
  "component---src-pages-documentation-projects-add-project-js": () => import("./../../../src/pages/documentation/projects/add-project.js" /* webpackChunkName: "component---src-pages-documentation-projects-add-project-js" */),
  "component---src-pages-documentation-projects-connect-to-jira-js": () => import("./../../../src/pages/documentation/projects/connect-to-jira.js" /* webpackChunkName: "component---src-pages-documentation-projects-connect-to-jira-js" */),
  "component---src-pages-documentation-projects-dashboard-js": () => import("./../../../src/pages/documentation/projects/dashboard.js" /* webpackChunkName: "component---src-pages-documentation-projects-dashboard-js" */),
  "component---src-pages-documentation-projects-view-all-projects-js": () => import("./../../../src/pages/documentation/projects/view-all-projects.js" /* webpackChunkName: "component---src-pages-documentation-projects-view-all-projects-js" */),
  "component---src-pages-documentation-testcase-create-test-case-js": () => import("./../../../src/pages/documentation/testcase/create-test-case.js" /* webpackChunkName: "component---src-pages-documentation-testcase-create-test-case-js" */),
  "component---src-pages-documentation-testcase-delete-test-case-js": () => import("./../../../src/pages/documentation/testcase/delete-test-case.js" /* webpackChunkName: "component---src-pages-documentation-testcase-delete-test-case-js" */),
  "component---src-pages-documentation-testcase-export-test-case-js": () => import("./../../../src/pages/documentation/testcase/export-test-case.js" /* webpackChunkName: "component---src-pages-documentation-testcase-export-test-case-js" */),
  "component---src-pages-documentation-testcase-import-test-case-js": () => import("./../../../src/pages/documentation/testcase/import-test-case.js" /* webpackChunkName: "component---src-pages-documentation-testcase-import-test-case-js" */),
  "component---src-pages-documentation-testcase-test-case-actions-js": () => import("./../../../src/pages/documentation/testcase/test-case-actions.js" /* webpackChunkName: "component---src-pages-documentation-testcase-test-case-actions-js" */),
  "component---src-pages-documentation-testcycle-create-test-cycle-js": () => import("./../../../src/pages/documentation/testcycle/create-test-cycle.js" /* webpackChunkName: "component---src-pages-documentation-testcycle-create-test-cycle-js" */),
  "component---src-pages-documentation-testcycle-delete-test-cycle-js": () => import("./../../../src/pages/documentation/testcycle/delete-test-cycle.js" /* webpackChunkName: "component---src-pages-documentation-testcycle-delete-test-cycle-js" */),
  "component---src-pages-documentation-testcycle-execute-test-cycle-js": () => import("./../../../src/pages/documentation/testcycle/execute-test-cycle.js" /* webpackChunkName: "component---src-pages-documentation-testcycle-execute-test-cycle-js" */),
  "component---src-pages-documentation-testcycle-link-to-jira-issue-js": () => import("./../../../src/pages/documentation/testcycle/link-to-jira-issue.js" /* webpackChunkName: "component---src-pages-documentation-testcycle-link-to-jira-issue-js" */),
  "component---src-pages-documentation-testcycle-test-cycle-actions-js": () => import("./../../../src/pages/documentation/testcycle/test-cycle-actions.js" /* webpackChunkName: "component---src-pages-documentation-testcycle-test-cycle-actions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-knowledgeshare-js": () => import("./../../../src/pages/knowledgeshare.js" /* webpackChunkName: "component---src-pages-knowledgeshare-js" */),
  "component---src-pages-leadershipteam-js": () => import("./../../../src/pages/leadershipteam.js" /* webpackChunkName: "component---src-pages-leadershipteam-js" */),
  "component---src-pages-legacymodernization-js": () => import("./../../../src/pages/legacymodernization.js" /* webpackChunkName: "component---src-pages-legacymodernization-js" */),
  "component---src-pages-mobiletesting-js": () => import("./../../../src/pages/mobiletesting.js" /* webpackChunkName: "component---src-pages-mobiletesting-js" */),
  "component---src-pages-mvpsportsclub-js": () => import("./../../../src/pages/mvpsportsclub.js" /* webpackChunkName: "component---src-pages-mvpsportsclub-js" */),
  "component---src-pages-nurseio-js": () => import("./../../../src/pages/nurseio.js" /* webpackChunkName: "component---src-pages-nurseio-js" */),
  "component---src-pages-performancetestengineering-js": () => import("./../../../src/pages/performancetestengineering.js" /* webpackChunkName: "component---src-pages-performancetestengineering-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-productdevelopment-js": () => import("./../../../src/pages/productdevelopment.js" /* webpackChunkName: "component---src-pages-productdevelopment-js" */),
  "component---src-pages-productengineering-js": () => import("./../../../src/pages/productengineering.js" /* webpackChunkName: "component---src-pages-productengineering-js" */),
  "component---src-pages-salesforcetesting-js": () => import("./../../../src/pages/salesforcetesting.js" /* webpackChunkName: "component---src-pages-salesforcetesting-js" */),
  "component---src-pages-species-360-js": () => import("./../../../src/pages/species360.js" /* webpackChunkName: "component---src-pages-species-360-js" */),
  "component---src-pages-startupstudio-js": () => import("./../../../src/pages/startupstudio.js" /* webpackChunkName: "component---src-pages-startupstudio-js" */),
  "component---src-pages-termsandconditions-js": () => import("./../../../src/pages/termsandconditions.js" /* webpackChunkName: "component---src-pages-termsandconditions-js" */),
  "component---src-pages-testautomation-js": () => import("./../../../src/pages/testautomation.js" /* webpackChunkName: "component---src-pages-testautomation-js" */),
  "component---src-pages-testingservice-js": () => import("./../../../src/pages/testingservice.js" /* webpackChunkName: "component---src-pages-testingservice-js" */)
}

